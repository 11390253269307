/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function e() {
  return [1, 0, 0, 1, 0, 0];
}
function r(e) {
  return [e[0], e[1], e[2], e[3], e[4], e[5]];
}
function t(e, r, t, n, o, u) {
  return [e, r, t, n, o, u];
}
function n(e, r) {
  return new Float64Array(e, r, 6);
}
const o = e(),
  u = Object.freeze(Object.defineProperty({
    __proto__: null,
    IDENTITY: o,
    clone: r,
    create: e,
    createView: n,
    fromValues: t
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { o as IDENTITY, r as clone, e as create, n as createView, t as fromValues, u as m };